import { GlobalContext } from 'providers/GlobalProvider'
import { useContext } from 'react'
import useViewportSize from 'hooks/useViewportSize'

/**
@see {@link https://bitbucket.org/billsdotcom_bills/sunbeam/src/main/src/styles/tokens/breakpoints/breakpoints.json | Sunbeam Breakpoints}
*/
const BREAKPOINTS = {
  xxs: 'xxs',
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg', // For launch, desktop designs begin at the `lg` breakpoint
  xl: 'xl',
  xxl: 'xxl',
}

const maxBreakpoints = {
  xxs: 0,
  xs: 320,
  sm: 480,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
}

/**
 * Check to see if a viewport is larger than a given breakpoint
 * @param {string} [breakpoint='xxs'] - xxs, xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
const useViewportLargerThan = (breakpoint = BREAKPOINTS.xxs) => {
  const { isMobileGlobal } = useContext(GlobalContext)
  return isMobileGlobal[`${breakpoint}Larger`]
}

/**
 * Check to see if a viewport is smaller than a given breakpoint
 * @param {string} [breakpoint='xxl'] - xxs, xs, sm, md, lg, xl, xxl
 * @returns boolean
 */
const useViewportSmallerThan = (breakpoint = BREAKPOINTS.xxl) => {
  const { isMobileGlobal } = useContext(GlobalContext)
  return isMobileGlobal[`${breakpoint}`]
}

/**
 * check all breakpoints regarding screen width
 * @returns object
 */
const useViewportCheckAllSizes = () => {
  const { screenWidth } = useViewportSize()
  return {
    xxs: screenWidth && screenWidth < maxBreakpoints.xxs,
    xs: screenWidth && screenWidth < maxBreakpoints.xs,
    sm: screenWidth && screenWidth < maxBreakpoints.sm,
    md: screenWidth && screenWidth < maxBreakpoints.md,
    lg: screenWidth && screenWidth < maxBreakpoints.lg,
    xl: screenWidth && screenWidth < maxBreakpoints.xl,
    xxl: screenWidth && screenWidth < maxBreakpoints.xxl,
    xxsLarger: screenWidth && screenWidth >= maxBreakpoints.xxs,
    xsLarger: screenWidth && screenWidth >= maxBreakpoints.xs,
    smLarger: screenWidth && screenWidth >= maxBreakpoints.sm,
    mdLarger: screenWidth && screenWidth >= maxBreakpoints.md,
    lgLarger: screenWidth && screenWidth >= maxBreakpoints.lg,
    xlLarger: screenWidth && screenWidth >= maxBreakpoints.xl,
    xxlLarger: screenWidth && screenWidth >= maxBreakpoints.xxl,
  }
}

export { useViewportLargerThan, useViewportSmallerThan, useViewportCheckAllSizes, BREAKPOINTS }

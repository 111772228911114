import { createContext } from 'react'
import { useViewportCheckAllSizes } from 'utils/mui'
const GlobalContext = createContext()

const GlobalProvider = ({ children }) => {
  const isMobile = useViewportCheckAllSizes()
  return (
    <GlobalContext.Provider value={{ isMobileGlobal: isMobile }}>{children}</GlobalContext.Provider>
  )
}

export { GlobalProvider, GlobalContext }

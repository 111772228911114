import getContentfulClient from 'services/contentful/client'
import { getSlug } from 'utils/conversions'

export const DEFAULT_LIST = [
  'home-equity-loans',
  'personal-loans',
  'resolve-debt',
  'debt-consolidation',
  'debt-resolution',
  'achieve-news',
  'careers',
  'community-involvement',
  'financial-health',
  'managing-debt',
]

export const DEFAULT_FILTER_LIST = {
  'home-equity-loans': 'Home Equity Loans',
  'personal-loans': 'Personal loans',
  'resolve-debt': 'Reolve Debt',
  'debt-consolidation': 'Debt Consolidation',
  'debt-resolution': 'Debt Resolution',
  'achieve-news': 'Achieve News',
  careers: 'Careers',
  'community-involvement': 'Community Involvement',
  'financial-health': 'Financial Health',
  'managing-debt': 'Managing Debt',
}

const articleCategoryList = (type = 'article', queryParameters = {}) => {
  return getContentfulClient(queryParameters)
    .getEntries({
      content_type: type,
      select: 'fields.primaryCategory',
    })
    .then((response) => {
      return response.items.map((e) => e?.fields?.primaryCategory)
    })
    .catch(() => {
      return DEFAULT_LIST
    })
}

export const filterArticleCategoryList = (type = 'article', queryParameters = {}) => {
  return getContentfulClient(queryParameters)
    .getEntries({
      content_type: type,
      select: 'fields.primaryCategory,fields.secondaryCategory',
    })
    .then((response) => {
      const element = {}
      response.items.map((e) => {
        element[getSlug(e?.fields?.primaryCategory)] = e?.fields?.primaryCategory
        e?.fields?.secondaryCategory?.map((secondary) => {
          element[getSlug(secondary)] = secondary
        })
      })

      return element
    })
    .catch(() => {
      return DEFAULT_FILTER_LIST
    })
}

export default articleCategoryList

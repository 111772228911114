/**
 * Check if window object is available
 */
const isWindowReady = () => typeof window !== 'undefined'

const getWindowDetails = () => {
  if (!isWindowReady()) {
    return
  }
  let result
  const { innerWidth: width, innerHeight: height } = window

  /**
   * pixelValues is a list of tuples containing the breakpoint abbreviation
   * as the key and the pixel number as the value, sorted in ascending
   * order so that a match can be found where the current screen width
   * is greater than pixelValues[i][1] and less than pixelValues[i + 1][1]
   */
  const pixelValues = [
    ['xxs', 0],
    ['xs', 320],
    ['sm', 480],
    ['md', 768],
    ['lg', 1024],
    ['xl', 1280],
    ['xxl', 1440],
  ]

  pixelValues.every(([key, value], i) => {
    if (width >= value && (i === pixelValues.length - 1 || width < pixelValues[i + 1][1])) {
      // break out of iteration once matching interval found
      result = {
        breakpointPixelSize: value,
        breakpointLetterSize: key,
        screenWidth: width,
        screenHeight: height,
        calculatedClient: true,
      }
      return false
    }
    // else continue iteration
    return true
  })
  return result
}

export { isWindowReady, getWindowDetails }
